// dependencies
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// components
import Layout from '../layout/layout'
import SEO from '../components/seo'
import Text from '../components/Text'
import SubHeader from '../components/Subheader'
import Button from '../components/Button'
import Gallery from '../components/Gallery'

// styles
import styles from './pages.module.css'

const IndexPage = props => {
    return (
        <Layout>
            <SEO
                title="Home"
                description="Sew Fabulous is an independent creator of custom pillows, slip
                covers, cushion covers and window treatments with a focus on
                attention to detail and excellent craftsmanship."
            />
            <SubHeader>About</SubHeader>
            <Text>
                Sew Fabulous is an independent creator of custom pillows, slip
                covers, cushion covers and window treatments with a focus on
                attention to detail and excellent craftsmanship.
            </Text>
            <Text>
                In response to COVID-19, Sew Fabulous has been using extra
                supplies and time to create homemade masks to prevent the
                purchase of masks desperately needed for healthcare workers.
            </Text>
            <Text>
                Masks can be purchased for $5 each plus applicable shipping.
                Each mask is made from two layers of 100% pre-washed cotton
                fabric with ribbon ties.
            </Text>
            <div className={styles.BannerImageContainer}>
                <Img fluid={props.data.banner.childImageSharp.fluid} />
            </div>
            <Button to="/contact">Request Masks or Home Treatments</Button>

            <div className={styles.GallerySection}>
                <SubHeader>Gallery</SubHeader>
                <Gallery />
            </div>
        </Layout>
    )
}

// graphql query fragment for getting fluid images
export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`

// graphql pageQuery to get individual images
export const pageQuery = graphql`
    query {
        banner: file(relativePath: { eq: "sewfabbanner.JPG" }) {
            ...fluidImage
        }
    }
`

export default IndexPage
