// dependencies
import React from 'react'

// styles
import styles from './components.module.css'

const Subheader = ({ children }) => {
    return <h1 className={styles.SubHeader}>{children}</h1>
}

export default Subheader
