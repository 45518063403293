// dependencies
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// styles
import styles from './Gallery.module.css'

const Gallery = () => {
    const data = useStaticQuery(graphql`
        query CloudinaryImage {
            allCloudinaryMedia {
                edges {
                    node {
                        secure_url
                    }
                }
            }
        }
    `)
    const images = data.allCloudinaryMedia.edges
    return (
        <div className={styles.Gallery}>
            {images.map((image, index) => (
                <div className={styles.ImageItemWrapper} key={`image-${index}`}>
                    <img
                        src={image.node.secure_url}
                        alt=" "
                        className={styles.ImageItem}
                    />
                </div>
            ))}
        </div>
    )
}

export default Gallery
