// dependencies
import React from 'react'

// styles
import styles from './components.module.css'

const Text = ({ children }) => {
    return <p className={styles.Text}>{children}</p>
}

export default Text
