// dependencies
import React from 'react'
import { Link } from 'gatsby'

// styles
import styles from './components.module.css'

const Button = ({ to, children }) => {
    return (
        <Link to={to} className={styles.Button}>
            {children}
        </Link>
    )
}

export default Button
